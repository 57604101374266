import React from 'react'
import classes from './ScoreChart.module.css'

export default props => {
  const scoreToChart = score => {
    switch (true) {
      case score >= 0 && score < 0.5:
        return 23.75 * 0 + (score / 0.5) * 23.75
      case score >= 0.5 && score < 0.7:
        return 23.75 * 1 + (23.75 * (score - 0.5)) / 0.195
      case score >= 0.7 && score < 0.9:
        return 23.75 * 2 + (23.75 * (score - 0.7)) / 0.195
      case score >= 0.9 && score <= 1:
        return 23.75 * 3 + (23.75 * (score - 0.9)) / 0.1
      case score > 1:
        return 23.75 * 4
      default:
        return 0
    }
  }

  const score = scoreToChart(props.score)

  return (
    <div className={classes.Main}>
      <div>
        <div className={classes.Chances}>
          <div>
            <span>low chance to pass actual exam</span>
          </div>
          <div>
            <span>high chance to pass actual exam</span>
          </div>
        </div>
        <div className={classes.Arrow}></div>
        <div className={classes.Marks}>
          <div className={classes.Mark}></div>
          <div className={classes.Mark}></div>
          <div className={classes.Mark}></div>
        </div>
      </div>
      <div className={classes.Score} style={{ left: `${score}%` }}>
        <center>
          <div className={classes.You}>YOU</div>
          <div className={classes.Line}></div>
        </center>
      </div>
      <div className={classes.Chart}>
        <div className={[classes.Sector, classes.NI].join(' ')}></div>
        <div className={[classes.Sector, classes.BT].join(' ')}></div>
        <div className={[classes.Sector, classes.T].join(' ')}></div>
        <div className={[classes.Sector, classes.AT].join(' ')}></div>
      </div>
      <div className={classes.Legend}>
        <div>
          <center>Needs Improvement</center>
        </div>
        <div>
          <center>Below Target</center>
        </div>
        <div>
          <center>Target</center>
        </div>
        <div>
          <center>Above Target</center>
        </div>
      </div>
    </div>
  )
}

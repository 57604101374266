export const hms = duration => {
  const hours = Math.floor(
    (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((duration % (1000 * 60)) / 1000)

  const formated = hours + 'h ' + minutes + 'm ' + seconds + 's'

  return formated
}

export const h = duration => {
  const hours = (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)

  const formated = hours >= 1 ? hours + 'h' : 60 * hours + 'm'

  return formated
}

export const mdy = timestamp => {
  const t = new Date(timestamp)
  const m = t.getMonth() + 1
  const d = t.getDate()
  const y = t.getFullYear()

  return `${m}/${d}/${y}`
}

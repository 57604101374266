export default (qa, qta) => {
  const score = qa / qta

  switch (true) {
    case score >= 0 && score < 0.5:
      return 'Needs Improvement'
    case score >= 0.5 && score < 0.7:
      return 'Below Target'
    case score >= 0.7 && score < 0.9:
      return 'Target'
    case score >= 0.9 && score <= 1:
      return 'Above Target'
    default:
      return 'Not Scored'
  }
}

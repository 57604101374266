import React from 'react'
import classes from './Questions.module.css'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Title from '../Title/Title'
import { Link } from 'gatsby'

export default props => {
  return (
    <div className={classes.Main}>
      <Title text={'Questions Statistics'} />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead className={classes.TableHead}>
            <TableRow>
              <TableCell align="left">Question</TableCell>
              <TableCell align="center">Field</TableCell>
              <TableCell align="center">Answer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.questions.map(row => (
              <TableRow key={row.link}>
                <TableCell component="th" scope="row">
                  <Link to={`/${row.link}`}>{row.question}</Link>
                </TableCell>
                <TableCell align="center">{row.field}</TableCell>
                <TableCell align="center">
                  <img
                    className={classes.Img}
                    src={row.result}
                    alt="result icon"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

import React, { Component } from 'react'
import classes from './Result.module.css'
import Item from './Item/Item'
import { mdy } from '../../../../utils/exam/timeFormater'
import clockIcon from '../../../../images/icons/alarm.svg'
import expandIcon from '../../../../images/icons/expand.svg'
import Fields from './Fields/Fields'
import Questions from './Questions/Questions'
import ScoreChart from './ScoreChart/ScoreChart'

class Result extends Component {
  state = {
    isExpanded: this.props.expanded
  }

  handleOnClick = () => {
    this.setState(prevState => {
      return {
        isExpanded: !prevState.isExpanded
      }
    })
  }

  render() {
    let fields = null
    let questions = null
    let scoreChart = null

    if (this.state.isExpanded) {
      fields = <Fields fields={this.props.fields} />
      questions = <Questions questions={this.props.questions} />
      scoreChart = <ScoreChart score={this.props.score} />
    }

    return (
      <div className={classes.Main}>
        <div
          className={classes.Items}
          onClick={this.handleOnClick}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <img src={clockIcon} alt="clock icon" />
          <Item value={mdy(this.props.date)} label="Date" width="90px" />
          <Item value={this.props.duration} label="Duration" width="90px" />
          <Item value={this.props.progress} label="Questions" width="90px" />
          <Item value={this.props.result} label="Result" width="170px" />
          <img
            src={expandIcon}
            className={[
              classes.ExpandIcon,
              this.state.isExpanded ? classes.Rotated : null
            ].join(' ')}
            alt="expand icon"
          />
        </div>
        {scoreChart}
        {fields}
        {questions}
      </div>
    )
  }
}

export default Result

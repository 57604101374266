import React from 'react'
import Layout from '../hoc/Layout/Layout'
import Statistics from '../components/Pages/Statistics/Statistics'
import SEO from '../components/seo'

export default () => (
  <Layout>
    <SEO
      title="Statistics | PMP Certification"
      keywords={[`PMP`, `PMP Exam`, `Project Management`]}
      description={`Exam Questions to get PMP Certification. Unlimited Questions to Sharpen Your Skills with In-depth Explanations.`}
    ></SEO>
    <Statistics />
  </Layout>
)

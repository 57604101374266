import React from 'react'
import classes from './Statistics.module.css'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'
import Alert from '@material-ui/lab/Alert'
import { Link } from 'gatsby'
import getRandomQuestion from '../../../utils/question/getRandom'
import Result from './Result/Result'
import resultChecker from '../../../utils/exam/resultChecker'
import CorrectIcon from '../../../images/icons/check_circle.svg'
import IncorrectIcon from '../../../images/icons/cancel.svg'
import Spinner from '../../UI/Spinner/Spinner'

const Statistics = props => {
  let res = null
  let spinner = null

  if (props.isAuthenticated) {
    if (!props.isFetched) {
      spinner = <Spinner />

      props.onResultsFetch(props.userId, props.token)
    } else {
      spinner = null
    }
  }

  if (props.results.length === props.examsCount && props.results.length !== 0) {
    if (props.examSuccess) props.onExamEraseSuccessMessage()

    res = props.results.map((r, i) => {
      const data = r.result

      const correctAnswers = data.reduce((acc, a) => {
        for (let k in a) acc += k === 'field' ? 0 : a[k] === true ? 1 : 0

        return acc
      }, 0)

      const fields = data.reduce((acc, a) => {
        if (!acc.includes(a.field)) acc.push(a.field)

        return acc
      }, [])

      let fieldsData = []

      for (let f of fields) {
        let answers = data.reduce((acc, a) => {
          if (a.field === f) {
            for (let k in a) {
              if (k !== 'field') acc.push(a[k])
            }
          }

          return acc
        }, [])

        fieldsData.push({ field: f, answers: answers })
      }

      for (let field of fieldsData) {
        let qta = field.answers.length
        let qa = field.answers.filter(a => a === true).length

        field['qa'] = qa
        field['qta'] = qta
        field['result'] = resultChecker(qa, qta)
      }

      const questions = data.reduce((acc, a) => {
        let answer = {}

        for (let k in a) {
          if (k !== 'field') {
            let q = k.split('-')
            q =
              q[q.length - 2].charAt(0).toUpperCase() +
              q[q.length - 2].slice(1) +
              ' ' +
              q[q.length - 1]

            answer = {
              question: q,
              field: a.field,
              result: a[k] === true ? CorrectIcon : IncorrectIcon,
              link: k
            }
          }
        }

        acc.push(answer)

        return acc
      }, [])

      const progress = `${correctAnswers}/${r.questionsToAnswer}`
      const result = resultChecker(correctAnswers, r.questionsToAnswer)

      return (
        <Result
          key={r.date}
          date={r.date}
          duration={r.duration}
          progress={progress}
          result={result}
          fields={fieldsData}
          questions={questions}
          score={correctAnswers / r.questionsToAnswer}
          expanded={i === 0 ? true : false}
        />
      )
    })
  } else {
    if (props.isFetched) {
      res = (
        <div className={classes.Message}>
          <Link to={`/${getRandomQuestion().url}`}>
            <Alert
              variant="outlined"
              severity="info"
              style={{ borderColor: '#604f93' }}
            >
              You don't have statistics yet. Please use PMP Exam Simulator to
              get some.
            </Alert>
          </Link>
        </div>
      )
    }
  }

  return (
    <div className={classes.Container}>
      <center>
        <h1 className={classes.Heading}>STATISTICS</h1>
      </center>
      {spinner}
      {res}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    results: state.statistics.exams,
    isAuthenticated: state.auth.token !== null,
    userId: state.auth.userId,
    token: state.auth.token,
    isFetched: state.statistics.isFetched,
    examsCount: state.statistics.examsCount,
    examSuccess: state.exam.examSuccess
  }
}

const mapActionToProps = dispatch => {
  return {
    onResultsFetch: (userId, token) =>
      dispatch(actions.resultFetch(userId, token)),
    onExamEraseSuccessMessage: () => dispatch(actions.examEraseSuccessMessage())
  }
}

export default connect(mapStateToProps, mapActionToProps)(Statistics)

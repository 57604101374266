import React from 'react'
import classes from './Fields.module.css'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Title from '../Title/Title'

export default props => {
  return (
    <div className={classes.Main}>
      <Title text={'Fields Statistics'} />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead className={classes.TableHead}>
            <TableRow>
              <TableCell align="left">Field</TableCell>
              <TableCell align="center">Questions</TableCell>
              <TableCell align="center">Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fields.map(row => (
              <TableRow key={row.field}>
                <TableCell component="th" scope="row">
                  {row.field}
                </TableCell>
                <TableCell align="center">
                  {row.qa}/{row.qta}
                </TableCell>
                <TableCell align="center">{row.result}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

import React from 'react'
import classes from './Item.module.css'
import TextField from '@material-ui/core/TextField'

export default props => (
  <div className={classes.Item}>
    <TextField
      label={props.label}
      InputProps={{
        readOnly: true
      }}
      value={props.value}
      style={{ width: props.width }}
    />
  </div>
)
